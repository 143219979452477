/**
 * Defines the nobt model (as in the M in MVC) used throughout the frontend.
 *
 * It is purposely framework agnostic.
 */
var Nobt = /** @class */ (function () {
    function Nobt(id, name, currency, members, debts, bills, deletedBills, createdOn) {
        this.id = id;
        this.name = name;
        this.currency = currency;
        this.members = members;
        this.debts = debts;
        this.bills = bills;
        this.deletedBills = deletedBills;
        this.createdOn = createdOn;
    }
    Nobt.fromServerResponse = function (response) {
        return new Nobt(response.id, response.name, response.currency, response.participatingPersons, response.debts, response.expenses.map(Bill.fromServerResponse), response.deletedExpenses.map(Bill.fromServerResponse), response.createdOn);
    };
    Nobt.empty = function () {
        return new Nobt('', '', '', [], [], [], [], '');
    };
    Object.defineProperty(Nobt.prototype, "total", {
        get: function () {
            return this.bills
                .filter(function (bill) { return !bill.deletedOn; })
                .map(function (bill) { return bill.sum; })
                .reduce(function (sum, current) { return sum + current; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nobt.prototype, "numberOfMembers", {
        get: function () {
            return this.members.length;
        },
        enumerable: true,
        configurable: true
    });
    Nobt.prototype.balanceOf = function (name) {
        return computeBalanceForPerson(this.debts, sanitizeName(name));
    };
    Object.defineProperty(Nobt.prototype, "balances", {
        get: function () {
            var _this = this;
            return this.members.map(function (name) { return computeBalanceForPerson(_this.debts, name); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nobt.prototype, "isEmpty", {
        get: function () {
            return this.bills.length === 0 && this.deletedBills.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    // TODO: `number` should be fine here, but it is hard to enforce the type on the outside
    Nobt.prototype.bill = function (id) {
        if (typeof id === 'string') {
            id = parseInt(id, 10);
        }
        return this.bills.concat(this.deletedBills).find(function (bill) { return bill.id === id; });
    };
    return Nobt;
}());
export { Nobt };
var Bill = /** @class */ (function () {
    function Bill(id, name, debteeName, date, createdOn, deletedOn, conversionInformation, shares, links) {
        this.id = id;
        this.name = name;
        this.debteeName = debteeName;
        this.date = date;
        this.createdOn = createdOn;
        this.deletedOn = deletedOn;
        this.conversionInformation = conversionInformation;
        this.shares = shares;
        this.links = links;
    }
    Bill.fromServerResponse = function (expense) {
        return new Bill(expense.id, expense.name, expense.debtee, expense.date, expense.createdOn, expense.deletedOn, expense.conversionInformation, expense.shares, expense._links || {});
    };
    Object.defineProperty(Bill.prototype, "canBeDeleted", {
        get: function () {
            return this.deleteLink !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bill.prototype, "deleteLink", {
        get: function () {
            return this.links.delete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bill.prototype, "debtors", {
        get: function () {
            return this.shares.map(function (share) { return ({
                name: share.debtor,
                amount: share.amount,
            }); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bill.prototype, "debtee", {
        get: function () {
            return {
                name: this.debteeName,
                amount: this.sum,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bill.prototype, "sum", {
        get: function () {
            return sumShares(this.shares);
        },
        enumerable: true,
        configurable: true
    });
    return Bill;
}());
export { Bill };
export function sumShares(shares) {
    return shares
        .map(function (share) { return share.amount; })
        .reduce(function (sum, amount) { return sum + amount; });
}
// quickfix for bug 277
function sanitizeName(name) {
    return name.trim();
}
/**
 * Computes the "balance" of a single person within the nobt.
 *
 * This functionality requires from rather convoluted handling of the data that we get from the server.
 * Moving this to the backend would simplify things here: https://github.com/nobt-io/api/issues/101
 */
export function computeBalanceForPerson(debt, ownName) {
    var summaries = debt
        .filter(function (tx) {
        return tx.debtee === ownName || tx.debtor === ownName;
    })
        .map(function (tx) {
        if (tx.debtor === ownName) {
            return {
                debtee: tx.debtee,
                amount: tx.amount * -1,
                debtor: tx.debtor,
            };
        }
        else {
            return tx;
        }
    })
        .map(function (tx) {
        if (tx.debtee === ownName) {
            return { name: tx.debtor, amount: tx.amount };
        }
        else {
            return { name: tx.debtee, amount: tx.amount };
        }
    });
    var total = summaries.reduce(function (sum, tx) { return sum + tx.amount; }, 0);
    return {
        me: { name: ownName, amount: total },
        persons: summaries,
    };
}

import factory from 'api/axiosFactory';
var Client = /** @class */ (function () {
    function Client() {
    }
    Client.delete = function (uri) {
        return Client.instance().delete(uri);
    };
    Client.fetchNobt = function (identifier) {
        return Client.instance().get("nobts/" + identifier);
    };
    Client.createNobt = function (nobt) {
        return Client.instance().post('nobts', JSON.stringify(nobt), {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        });
    };
    Client.createBill = function (identifier, bill) {
        return Client.instance().post("nobts/" + identifier + "/expenses", JSON.stringify(bill), { headers: { 'Content-Type': 'application/json; charset=UTF-8' } });
    };
    Client.instance = function () {
        if (Client.axiosInstance === null) {
            Client.axiosInstance = factory(window.location.host);
        }
        return Client.axiosInstance;
    };
    Client.axiosInstance = null;
    return Client;
}());
export default Client;

import { useRouteMatch } from 'react-router';
export default function usePaths() {
    var match = useRouteMatch('/:nobtId');
    if (!match) {
        var fail_1 = function () {
            throw new Error('wrong usage of usePaths');
        };
        return {
            feed: fail_1,
            balances: fail_1,
            balanceFor: fail_1,
            billDetails: fail_1,
            newBill: fail_1,
        };
    }
    var nobtId = match.params.nobtId;
    return {
        feed: function () { return "/" + nobtId; },
        balances: function () { return "/" + nobtId + "/balances"; },
        balanceFor: function (name) { return "/" + nobtId + "/balances/" + name; },
        billDetails: function (id) { return "/" + nobtId + "/" + id; },
        newBill: function (subPage) {
            if (subPage === void 0) { subPage = ''; }
            return "/" + nobtId + "/bill/" + subPage;
        },
    };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a, _b;
import { ADD_MEMBER, INVALIDATE_NOBT, UPDATE_FETCH_NOBT_STATUS, } from './actions';
import { AsyncActionStatus } from 'const/AsyncActionStatus';
import _debug from 'debug';
var emptyHandler = function (status) { return ({ data: {} }); };
var updateFetchNobtStatusActionPayloadHandler = (_a = {},
    _a[AsyncActionStatus.IN_PROGRESS] = emptyHandler,
    _a[AsyncActionStatus.FAILED] = emptyHandler,
    _a[AsyncActionStatus.SUCCESSFUL] = function (payload) { return ({
        data: {
            id: payload.nobt.id,
            name: payload.nobt.name,
            currency: payload.nobt.currency,
            participatingPersons: payload.nobt.participatingPersons,
            transactions: __spreadArrays((payload.nobt.transactions || []), (payload.nobt.debts || [])),
            bills: __spreadArrays((payload.nobt.expenses || []), (payload.nobt.deletedExpenses || [])),
            createdOn: payload.nobt.createdOn,
            conversionInformation: payload.nobt.conversionInformation,
        },
    }); },
    _a);
var handlers = (_b = {},
    _b[UPDATE_FETCH_NOBT_STATUS] = function (state, action) {
        var handler = updateFetchNobtStatusActionPayloadHandler[action.payload.status] ||
            emptyHandler;
        var newState = handler(action.payload);
        return __assign(__assign(__assign({}, state), newState), { data: __assign(__assign({}, state.data), newState.data), nobtFetchTimestamp: Date.now(), fetchNobtStatus: action.payload.status });
    },
    _b[ADD_MEMBER] = function (state, action) {
        var currentMembers = state.data.participatingPersons;
        var memberToAdd = action.payload.name;
        if (currentMembers.find(function (name) { return name === memberToAdd; }) !== undefined) {
            _debug(ADD_MEMBER)("Person with name '" + memberToAdd + "' already exists.");
            return state;
        }
        var newData = __assign(__assign({}, state.data), { participatingPersons: __spreadArrays(currentMembers, [memberToAdd]) });
        return __assign(__assign({}, state), { data: newData });
    },
    _b[INVALIDATE_NOBT] = function (state) {
        return __assign(__assign({}, state), { nobtFetchTimestamp: null });
    },
    _b);
export var initialState = {
    fetchNobtStatus: null,
    nobtFetchTimestamp: null,
    data: {
        id: '',
        name: '',
        currency: '',
        participatingPersons: [],
        transactions: [],
        bills: [],
        payments: [],
        createdOn: '',
    },
};
export default function currentNobt(state, action) {
    if (state === void 0) { state = initialState; }
    var handler = handlers[action.type];
    if (!handler) {
        _debug('reducer:currentNobt')('[WARN] No handler found for ', action);
        return state;
    }
    return handler(state, action);
}
